import { ColumnDescriptorProps, ListTable } from '../../../../../components/table/ListTable';
import { LogDeviceWhitelist } from './LogDeviceWhitelistForm';
import Tag from '@rio-cloud/rio-uikit/Tag';
import { useIntl } from 'react-intl';
import { NoData } from '../../../../../components/PropertyTableFieldDefinitions';
import React, { useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { LogDeviceWhitelistFormDialog } from './LogDeviceWhitelistFormDialog';
import { useDeleteMutation, useGetAllQuery } from '../../../../../services/logDeviceWhitelistApi';
import Notification from '@rio-cloud/rio-uikit/Notification';

export const LogDeviceWhitelistList = () => {
    const { formatMessage } = useIntl();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<LogDeviceWhitelist | undefined>();
    const { data: data, error, isLoading } = useGetAllQuery();
    const [deleteLogDeviceWhitelist] = useDeleteMutation();

    const editRow = (item: LogDeviceWhitelist | undefined) => {
        setSelectedItem(item);
        setShowEditForm(true);
    };

    const deleteRow = (item: LogDeviceWhitelist | undefined) => {
        setShowDeleteConfirmation(true);
        setSelectedItem(item);
    };

    const deleteItem = async () => {
        if (!selectedItem) {
            throw Error('No item selected.');
        }
        try {
            await deleteLogDeviceWhitelist(`${selectedItem.type.toLowerCase()}:${selectedItem.serial_number}`).unwrap();
            Notification.info('Successfully deleted');
        } catch {
            Notification.error('Could not delete');
        } finally {
            setShowDeleteConfirmation(false);
            setSelectedItem(undefined);
        }
    };

    const columnDescriptors: ColumnDescriptorProps<LogDeviceWhitelist>[] = [
        {
            id: 'serialNumber',
            field: 'serial_number',
            label: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.serialNumber',
            format: field => <span>{field}</span>,
        }, {
            id: 'type',
            field: 'type',
            label: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.deviceType',
            className: 'text-center',
            format: field => <div className="label label-primary label-condensed">{field}</div>,
        }, {
            id: 'cids',
            field: 'cids',
            label: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.contentIds',
            format: (_, item?: LogDeviceWhitelist) =>
                <span>
            {
                (!item?.cids || item?.cids?.length === 0) ?
                    <NoData />
                    :
                    item.cids.map((cid, index) => (
                        <Tag className="margin-right-10 margin-bottom-5" key={`cid-${index}`}>{cid}</Tag>
                    ))
            }
            </span>,
        }, {
            id: 'requester',
            field: 'requester',
            label: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.requester',
            format: field => <span>{field}</span>,
        }, {
            id: 'reason',
            field: 'reason',
            label: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.reason',
            format: field => <span>{field}</span>,
        }, {
            id: 'handler',
            field: 'originator',
            format: (_, item?: LogDeviceWhitelist) =>
                <>
                    <div className="btn-toolbar margin-bottom-20">
                        <div className="btn-group">
                            <button type="button" className="btn btn-default" onClick={() => editRow(item)}>
                                <span className="rioglyph rioglyph-pencil text-size-14 margin-right-0" />
                            </button>
                            <button type="button" className="btn btn-default" onClick={() => deleteRow(item)}>
                                <span className="rioglyph rioglyph-trash text-size-14" />
                            </button>
                        </div>
                    </div>
                </>,
        },
    ];

    const addNewDeviceWhitelist = () => {
        setSelectedItem(undefined);
        setShowEditForm(true);
    };

    return (
        <>
            <div className="form-group">
                <div className="display-flex justify-content-end">
                    <button type="button" className="btn btn-default margin-right-10" onClick={addNewDeviceWhitelist}>
                        Add new
                    </button>
                </div>
            </div>
            <ListTable items={isLoading ? [] : data!}
                       isLoading={isLoading}
                       itemKey="originator"
                       showHeader
                       columnDescriptors={columnDescriptors}
            />
            <ConfirmationDialog
                show={showDeleteConfirmation}
                title={formatMessage({ id: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.deleteDialog.title' })}
                content={formatMessage({ id: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.deleteDialog.content' })}
                bsSize={Dialog.SIZE_SM}
                onClickConfirm={() => deleteItem()}
                onClickCancel={() => setShowDeleteConfirmation(false)}
                cancelButtonText={formatMessage({ id: 'intl-msg:coreconAdminWeb.cancel' })}
                confirmButtonText={
                    <>
                        <span className="rioglyph rioglyph-ok margin-right-5" />
                        <span>{formatMessage({ id: 'intl-msg:coreconAdminWeb.confirm' })}</span>
                    </>
                }
                useOverflow />
            <LogDeviceWhitelistFormDialog show={showEditForm}
                                          edit={selectedItem}
                                          onClose={() => setShowEditForm(false)} />
        </>
    );
};
