import AuthorisationWrapper from '../../../../components/utils/authorisation/authorisationWrapper';
import { FormattedMessage } from 'react-intl';
import { HEATMAP_READ } from '../../../../components/utils/roleUtils';
import { LogDeviceWhitelistList } from './components/LogDeviceWhitelistList';

export const LogDeviceWhitelistHome = () => {
    return (
        <AuthorisationWrapper allowedRoles={[HEATMAP_READ]}>
            <span>
                <h1 key={'header'}>
                    <FormattedMessage id={'intl-msg:coreconAdminWeb.logDeviceWhitelist.moduleName'} />
                </h1>
                <LogDeviceWhitelistList />
            </span>
        </AuthorisationWrapper>
    );
};
