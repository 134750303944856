import React from 'react';
import {useIntl} from 'react-intl';
import DateRangePicker from '@rio-cloud/rio-uikit/DateRangePicker';
import moment from 'moment';
import {Moment} from 'moment/moment';
import {
    deviceTypeFilterOptions,
    SIGNING_STATE,
    SigningFilter,
    signingStateFilterOptions
} from '../signing.types';
import {DEVICE_TYPE} from '../../device-sidebar/device-state.types';
import map from 'lodash/fp/map';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';

interface SigningFilterProps {
    signingFilter: SigningFilter;
    onUpdateSelectedSigningState: (signingState: SIGNING_STATE) => void;
    onUpdateSelectedDeviceType: (deviceType: DEVICE_TYPE) => void;
    onTimeRangeChange: (startValue: moment.Moment, endValue: moment.Moment) => void;
};

export const SigningFilterInput = (signingFilterProps: SigningFilterProps) => {
    const {formatMessage, locale} = useIntl();
    const {
        signingFilter,
        onUpdateSelectedSigningState,
        onUpdateSelectedDeviceType,
        onTimeRangeChange,
    } = signingFilterProps;

    const generateSigningStateToggleButtons = () => {
        return map((option) => {
            return <Button
                id={option.id}
                key={option.id}
                active={signingFilter.signingState === option.id}
                onClick={() => onUpdateSelectedSigningState(option.id)}
            >
                {formatMessage({id: option.label})}
            </Button>;
        }, signingStateFilterOptions);
    };

    const generateDeviceTypeToggleButtons = () => {
        return map((option) => {
            return <Button
                id={option.id}
                key={option.id}
                active={signingFilter.deviceType === option.id}
                onClick={() => onUpdateSelectedDeviceType(option.id)}
            >
                {formatMessage({id: option.label})}
            </Button>;
        }, deviceTypeFilterOptions);
    };

    return (
        <div className="padding-left-5">
            <div className="display-flex row padding-top-20 padding-bottom-1">
                <div className="padding-5">
                    {
                        generateSigningStateToggleButtons()
                    }
                </div>
                <div className="padding-5">
                    {
                        generateDeviceTypeToggleButtons()
                    }
                </div>
                <span className="input-group">
                    <div className="width-400">
                        <DateRangePicker locale="de-DE"
                                         hasTimePicker
                                         textToday={formatMessage({
                                             id: 'intl-msg:coreconAdminWeb.dateRangePicker.today'
                                         })}
                                         textLastWeek={formatMessage({
                                             id: 'intl-msg:coreconAdminWeb.dateRangePicker.oneWeek'
                                         })}
                                         textLastMonth={formatMessage({
                                             id: 'intl-msg:coreconAdminWeb.dateRangePicker.oneMonth'
                                         })}
                                         textCustom={formatMessage({
                                             id: 'intl-msg:coreconAdminWeb.dateRangePicker.customRange'
                                         })}
                                         textFrom={formatMessage({
                                             id: 'intl-msg:coreconAdminWeb.dateRangePicker.from'
                                         })}
                                         textTo={formatMessage({
                                             id: 'intl-msg:coreconAdminWeb.dateRangePicker.to'
                                         })}
                                         textApply={formatMessage({
                                             id: 'intl-msg:coreconAdminWeb.dateRangePicker.ok'
                                         })}
                                         textCancel={formatMessage({
                                             id: 'intl-msg:coreconAdminWeb.cancel'
                                         })}
                                         customPresets={[{
                                             text: formatMessage(
                                                 {id: 'intl-msg:coreconAdminWeb.dateRangePicker.today'}
                                             ),
                                             startValue: moment().startOf('day'),
                                             endValue: moment().endOf('day'),
                                         }, {
                                             text: formatMessage(
                                                 {id: 'intl-msg:coreconAdminWeb.dateRangePicker.oneWeek'}
                                             ),
                                             startValue: moment().startOf('day').subtract(1, 'weeks'),
                                             endValue: moment().endOf('day'),
                                         }, {
                                             text: formatMessage(
                                                 {id: 'intl-msg:coreconAdminWeb.dateRangePicker.oneMonth'}
                                             ),
                                             startValue: moment().startOf('day').subtract(1, 'months'),
                                             endValue: moment().endOf('day'),
                                         }]}
                                         startValue={moment(signingFilter.startDateTime)}
                                         endValue={moment(signingFilter.endDateTime)}
                                         onRangeChange={
                                             (startValue: Moment, endValue: Moment) =>
                                                 onTimeRangeChange(startValue, endValue)
                                         }/>
                    </div>
                </span>
            </div>
        </div>
    );
};
