import Tag from '@rio-cloud/rio-uikit/Tag';
import TagList from '@rio-cloud/rio-uikit/TagList';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import { ChangeEvent, FormEvent, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { DEVICE_TYPE } from '../../device-sidebar/device-state.types';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';

export interface LogDeviceWhitelist {
    originator: string;
    serial_number: string;
    type: DEVICE_TYPE;
    cids?: string[];
    requester?: string;
    reason: string;
}

interface LogDeviceWhitelistFormProps {
    edit?: LogDeviceWhitelist;
    onSubmit: (data: LogDeviceWhitelist) => void;
}

export const LogDeviceWhitelistForm = forwardRef(({ edit, onSubmit }: LogDeviceWhitelistFormProps, ref) => {
    const { formatMessage } = useIntl();
    const formRef = useRef<HTMLFormElement>(null);
    useImperativeHandle(ref, () => {
        return {
            submitForm: () => {
                if (formRef.current) {
                    formRef.current.requestSubmit();
                }
            },
        };
    });
    const [formData, setFormData] = useState<LogDeviceWhitelist>(() =>
        edit ?? {
            originator: '',
            serial_number: '',
            type: DEVICE_TYPE.CM4,
            reason: '',
        });
    const [cidInput, setCidInput] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [errors, setErrors] = useState<{
        serialNumber?: string;
        reason?: string;
        checkbox?: string;
    }>({});

    const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({
            ...prev,
            type: e.target.id as DEVICE_TYPE,
        }));
    };

    const onSerialNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFormData(prev => ({
            ...prev,
            serial_number: value,
            originator: `${formData.type.toLowerCase()}:${value}`,
        }));
        const isValid = /^\d{6}-\d{4}$/.test(value) || value === '';
        setErrors(prev => ({
            ...prev,
            serialNumber: isValid ? undefined : formatMessage(
                { id: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.serialNumber.validation.format' },
            ),
        }));
    };

    const onReasonChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setFormData(prev => ({ ...prev, reason: value }));
        if (value) {
            setErrors(prevState => ({
                ...prevState,
                reason: undefined,
            }));
        }
    };

    const onAddCidClick = () => {
        cidInput.split(/[,; ]+/)
            .filter(cid => /^\d+$/.test(cid))
            .map(cid => setFormData(prevState => ({
                ...prevState,
                cids: [...(prevState.cids ?? []), cid.trim()],
            })));
        setCidInput('');
    };

    const onRemoveCidClick = (index: number) => {
        setFormData(prevState => ({
            ...prevState,
            cids: (prevState.cids ?? []).filter((_, i) => i !== index),
        }));
    };

    const validateForm = () => {
        const newErrors: { serialNumber?: string; reason?: string; checkbox?: string } = {};

        if (!formData.serial_number) {
            newErrors.serialNumber = formatMessage({
                id: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.serialNumber.validation.missing',
            });
        }
        if (!formData.reason) {
            newErrors.reason = formatMessage({
                id: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.reason.validation.missing',
            });
        }
        if (!isChecked) {
            newErrors.checkbox = 'checkboxError';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            onSubmit(formData);
        } else {
            console.error(errors);
        }
    };

    return (
        <>
            <div className="row equal-height">
                <div className="col-12 margin-bottom-15">
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className={'form-group col-md-6 col-ls-12' +
                                `${errors.serialNumber ? ' has-feedback has-error' : ''}`}>
                                <label htmlFor="serialNumber">
                                    <span>
                                        <FormattedMessage
                                            id={'intl-msg:coreconAdminWeb.logDeviceWhitelist.serialNumber'} /> *
                                    </span>
                                </label>
                                <input type="text" className="form-control" id="serialNumber"
                                       value={formData.serial_number}
                                       readOnly={!!edit?.serial_number}
                                       tabIndex={0}
                                       onChange=
                                           {onSerialNumberChange}
                                       placeholder="XXXXXX-YYYY" autoComplete="off" />
                                {errors.serialNumber && (
                                    <span className="help-block margin-bottom-10">
                                        <span>{errors.serialNumber}</span>
                                    </span>
                                )}
                            </div>
                        </div>
                        <label>
                            <span>
                                <FormattedMessage
                                    id={'intl-msg:coreconAdminWeb.logDeviceWhitelist.deviceType'} /> *
                            </span>
                        </label>
                        <div className="form-group row">
                            <div className="col-4">
                                <label className="radio" htmlFor="cm4">
                                    <input type="radio" id="cm4" name="radioGroup"
                                           checked={formData.type === DEVICE_TYPE.CM4}
                                           tabIndex={1}
                                           readOnly={!!edit?.type}
                                           onChange={handleRadioChange} />
                                    <span className="radio-text">
                                        <span>CM4</span>
                                    </span>
                                </label>
                            </div>
                            <div className="col-4">
                                <label className="radio" htmlFor="tbm3">
                                    <input type="radio" id="tbm3" name="radioGroup" disabled
                                           readOnly={!!edit?.type}
                                           tabIndex={2}
                                           checked={formData.type === DEVICE_TYPE.TBM3}
                                           onChange={handleRadioChange} />
                                    <span className="radio-text">
                                        <span>TBM3 - NOT YET IMPLEMENTED</span>
                                    </span>
                                </label>
                            </div>
                            <div className="col-4">
                                <label className="radio" htmlFor="ocu3">
                                    <input type="radio" id="ocu3" name="radioGroup" disabled
                                           readOnly={!!edit?.type}
                                           tabIndex={2}
                                           checked={formData.type === DEVICE_TYPE.OCU3}
                                           onChange={handleRadioChange} />
                                    <span className="radio-text">
                                        <span>OCU3 - NOT YET IMPLEMENTED</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className={'form-group' + `${errors.reason ? ' has-feedback has-error' : ''}`}>
                            <label htmlFor="reason">
                                <span>
                                    <FormattedMessage
                                        id={'intl-msg:coreconAdminWeb.logDeviceWhitelist.reason'} /> *
                                </span>
                            </label>
                            <textarea className="form-control" rows={2} id="reason" value={formData.reason}
                                      tabIndex={3}
                                      onChange={onReasonChange} />
                            {errors.reason && (
                                <span className="help-block margin-bottom-10">
                                    <span>{errors.reason}</span>
                                </span>
                            )}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="form-group col-md-6 col-ls-12">
                                <label htmlFor="content-id"
                                       className="display-inline-flex flex-wrap justify-content-between width-100pct">
                                    <FormattedMessage
                                        id={'intl-msg:coreconAdminWeb.logDeviceWhitelist.contentIds'} />
                                    <span className="text-italic">
                                        <FormattedMessage
                                            id={'intl-msg:coreconAdminWeb.logDeviceWhitelist.contentIds.hint'} />
                                    </span>
                                </label>
                                <div className="input-group">
                                    <div className="ClearableInput input-group">
                                        <input id="contend-id"
                                               placeholder={
                                                   formatMessage({
                                                       id: 'intl-msg:' +
                                                           'coreconAdminWeb.logDeviceWhitelist.contentIds.placeholder',
                                                   })}
                                               className="form-control"
                                               type="text" tabIndex={4} value={cidInput}
                                               onChange={e => setCidInput(e.target.value)} />
                                    </div>
                                    <span className="input-group-btn">
                                        <Button bsStyle={Button.PRIMARY} variant={Button.VARIANT_OUTLINE}
                                                onClick={onAddCidClick}>
                                            <span className="rioglyph rioglyph-plus-light" />
                                        </Button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <TagList>
                            {formData.cids?.map((cid, index) => (
                                <Tag key={`cid-${index}`} deletable onClick={() => onRemoveCidClick(index)}>
                                    {cid}
                                </Tag>
                            ))}
                        </TagList>
                        <hr />
                        <div className="form-group">
                            <label />
                            <div className="col-md-6 col-ls-12">
                                <Checkbox
                                    label={
                                        <span>
                                        <FormattedMessage
                                            id={'intl-msg:coreconAdminWeb.logDeviceWhitelist.termsAndConditions'} /> *
                                    </span>}
                                    tabIndex={5}
                                    error={!!errors.checkbox}
                                    checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
});
LogDeviceWhitelistForm.displayName = 'LogDeviceWhitelistForm';