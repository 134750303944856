import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './utils';
import { LogDeviceWhitelist } from '../features/app/context/log-device-whitelist/components/LogDeviceWhitelistForm';
import { config } from '../config';
import { DEVICE_TYPE } from '../features/app/context/device-sidebar/device-state.types';

const REQUEST_MAPPING = '/log-device-whitelist';
const TAG = 'LogDeviceWhitelist';

const transformInput = (logDeviceWhitelist: LogDeviceWhitelist) => ({
    ...logDeviceWhitelist,
    type: logDeviceWhitelist.type.toLowerCase(),
});

export const logDeviceWhitelistApi = createApi({
    reducerPath: 'logDeviceWhitelist',
    tagTypes: [TAG],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_STATE_TBM3_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getAll: builder.query<LogDeviceWhitelist[], void>({
            query: () => REQUEST_MAPPING,
            providesTags: [TAG],
            transformResponse: (response: LogDeviceWhitelist[]) => response.map(item => ({
                ...item,
                type: item.type.toUpperCase() as DEVICE_TYPE,
            })),
        }),
        get: builder.query<LogDeviceWhitelist, string>({
            query: (originator) => `${REQUEST_MAPPING}/${originator}`,
        }),
        add: builder.mutation<LogDeviceWhitelist[], LogDeviceWhitelist>({
            query: (newLogDeviceWhitelist) => ({
                url: REQUEST_MAPPING,
                method: 'POST',
                body: [transformInput(newLogDeviceWhitelist)],
            }),
            invalidatesTags: [TAG],
        }),
        update: builder.mutation<LogDeviceWhitelist[], LogDeviceWhitelist>({
            query: (updatedLogDeviceWhitelist) => ({
                url: REQUEST_MAPPING,
                method: 'PUT',
                body: [transformInput(updatedLogDeviceWhitelist)],
            }),
            invalidatesTags: [TAG],
        }),
        delete: builder.mutation<void, string>({
            query: (originator) => ({
                url: `${REQUEST_MAPPING}/${originator}`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAG],
        }),
    }),
});

export const {
    useGetAllQuery,
    useGetQuery,
    useAddMutation,
    useUpdateMutation,
    useDeleteMutation,
} = logDeviceWhitelistApi;