import { FormattedMessage } from 'react-intl';
import { Link, NavLink } from 'react-router-dom';

import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';

import { config } from '../../../config';
import ServiceInfo from './ServiceInfo';
import { AppHeaderProps } from './AppHeader.types';
import { ROUTE_HOME_DEVICE_STATE } from '../context/device-state/deviceState.routes';
import { ROUTE_HOME_UPLOAD } from '../context/upload/upload.routes';
import { ROUTE_HOME_CERTIFICATES } from '../context/certificates/certificates.routes';
import { ROUTE_HOME_OVERVIEW } from '../context/overview/overview.routes';
import { ROUTE_HOME_SIGNING } from '../context/device-signing/signing.routes';
import { ROUTE_HOME_LOG_DEVICE_WHITELIST } from '../context/log-device-whitelist/logDeviceWhitelist.routes';
import { ThemeToggler } from './ThemeToggler';
import { isAuthorised } from '../../../components/utils/authorisation/authorisationUtils';
import { CORECON_READ, HEATMAP_READ } from '../../../components/utils/roleUtils';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIdToken } from '../../../configuration/tokenHandling/tokenSlice';
import { RootState } from '../../../configuration/setup/store';

interface NavItem {
    key: string;
    route: ReactNode;
    allowedRoles?: string[];
}

const NAV_ITEMS_CONFIG = [
    {
        key: 'overview',
        route: (
            <NavLink to={ROUTE_HOME_OVERVIEW}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.overview'} />
            </NavLink>
        ),
    }, {
        key: 'deviceState',
        route: (
            <NavLink to={ROUTE_HOME_DEVICE_STATE}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.deviceState.moduleName'} />
            </NavLink>
        ),
        allowedRoles: [CORECON_READ],
    },
    {
        key: 'journal',
        route: (
            <NavLink to={ROUTE_HOME_UPLOAD}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.upload.moduleName'} />
            </NavLink>
        ),
    },
    {
        key: 'certificates',
        route: (
            <NavLink to={ROUTE_HOME_CERTIFICATES}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.serviceCertificates.moduleName'} />
            </NavLink>
        ),
    },
    {
        key: 'signing',
        route: (
            <NavLink to={ROUTE_HOME_SIGNING}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.deviceSigning.moduleName'} />
            </NavLink>
        ),
    }, {
        key: 'log-device-whitelist',
        route: (
            <NavLink to={ROUTE_HOME_LOG_DEVICE_WHITELIST}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.logDeviceWhitelist.moduleName'} />
            </NavLink>
        ),
        allowedRoles: [HEATMAP_READ],
    },
];

export const environment = process.env.NODE_ENV === 'production' ? 'production' : 'local';

export const serviceInfoItem = <ServiceInfo />;
export const userMenuItem = <DefaultUserMenu environment={environment} />;

const AppHeader = (props: AppHeaderProps) => {

    const idToken = useSelector((state: RootState) => getIdToken(state));
    const [authorisedNavItems, setAuthorisedNavItems] = useState<NavItem[]>([]);

    useEffect(() => {
        const checkAuthorisation = () => {
            const filteredItems = NAV_ITEMS_CONFIG.filter(
                item => !item.allowedRoles || isAuthorised(item.allowedRoles),
            );
            if (JSON.stringify(filteredItems) !== JSON.stringify(authorisedNavItems)) {
                setAuthorisedNavItems(filteredItems);
            }
        };
        checkAuthorisation();
    }, [idToken]);

    return (
        <ApplicationHeader
            label={<FormattedMessage id={'intl-msg:starterTemplate.moduleName'} />}
            homeRoute={<Link to={config.homeRoute || ''} />}
            navItems={authorisedNavItems}
            actionBarItems={[ThemeToggler()]}
        />
    );
};

export default AppHeader;
